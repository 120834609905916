import type { VariantProps } from "class-variance-authority";
import type { HTMLAttributes } from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import { cn } from "~/lib/utils";

const typographyVariants = cva("text-foreground", {
  defaultVariants: {
    variant: "p",
  },
  variants: {
    variant: {
      blockquote: "mt-6 border-l-2 pl-6 italic",
      caption: "text-xs text-muted-foreground",
      h1: "scroll-m-20 font-serif text-4xl font-semibold tracking-tight lg:text-5xl",
      h2: "scroll-m-20 font-serif text-3xl font-semibold tracking-tight first:mt-0",
      h3: "scroll-m-20 font-serif text-2xl font-semibold tracking-tight",
      h4: "scroll-m-20 font-serif text-xl font-semibold tracking-tight",
      h5: "scroll-m-20 font-serif text-lg font-semibold tracking-tight",
      h6: "scroll-m-20 font-serif text-base font-semibold tracking-tight",
      hero: "scroll-m-20 font-serif text-6xl font-semibold tracking-tighter lg:text-8xl",
      inlineCode:
        "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
      interviewMessage: "text-sm leading-snug tracking-tight lg:text-base",
      largeText: "text-lg font-semibold",
      lead: "text-2xl",
      li: "text-sm",
      metric: "text-3xl font-semibold tracking-tight text-primary",
      mutedText: "text-sm text-muted-foreground",
      ol: "ml-6 list-decimal",
      p: "leading-7",
      smallText: "text-sm font-medium leading-tight",
      transcript:
        "scroll-m-20 text-2xl font-medium tracking-tight sm:font-semibold",
      ul: "ml-6 list-disc",
    },
  },
});

type VariantPropType = VariantProps<typeof typographyVariants>;

const variantElementMap: Record<
  NonNullable<VariantPropType["variant"]>,
  string
> = {
  blockquote: "blockquote",
  caption: "p",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  hero: "h1",
  inlineCode: "code",
  interviewMessage: "p",
  largeText: "div",
  lead: "p",
  li: "li",
  metric: "p",
  mutedText: "p",
  ol: "ol",
  p: "p",
  smallText: "small",
  transcript: "h1",
  ul: "ul",
};

export interface TypographyProps
  extends HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {
  as?: string;
  asChild?: boolean;
}

const Typography = forwardRef<HTMLElement, TypographyProps>(
  ({ as, asChild, className, variant, ...props }, ref) => {
    const Comp =
      asChild === true
        ? Slot
        : as ??
          (variant == null ? undefined : variantElementMap[variant]) ??
          "div";
    return (
      <Comp
        className={cn(typographyVariants({ className, variant }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Typography.displayName = "Typography";

export { Typography, typographyVariants };
